




















import { Component, Mixins, Provide } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { DrawerWrapper, Inject, IS_MOBILE_PROVIDER_KEY, ISiteService, SiteServiceType } from '@core'
import { ModalWrapper } from '@/shared/core/src/front/shared/organisms/ModalWrapper'
import { CoreMixin } from '@/shared/core/src/front/shared/mixins'

import { ErrorDetails } from '@component/ErrorDetails.vue'
import { getHtmlLangAttrValue } from '@support/i18n'
import { isLocalEnv } from '@support/env'

import { HasTheme } from '@module/content/shared/HasTheme.mixin.vue'
import { ProductCartMixin } from './shared/core/src/front/checkout/shared/mixins/product-cart.mixin'

@Component<App>({
  name: 'App',
  components: { DrawerWrapper, ErrorDetails, ModalWrapper },
  metaInfo (): MetaInfo {
    return {
      htmlAttrs: {
        lang: getHtmlLangAttrValue(this.$i18n.locale)
      },
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${process.env.BASE_URL}img/icons/favicon-32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${process.env.BASE_URL}img/icons/favicon-16x16.png`
        },
        {
          rel: 'icon',
          href: `${process.env.BASE_URL}img/icons/favicon.ico`
        },
        {
          rel: 'manifest',
          href: `${process.env.BASE_URL}site.webmanifest`
        },
        {
          rel: 'mask-icon',
          href: `${process.env.BASE_URL}img/icons/safari-pinned-tab.svg`,
          color: '#000000'
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${process.env.BASE_URL}img/icons/apple-touch-icon.png`
        },
        {
          rel: 'preconnect',
          href: process.env.VUE_APP_HTTP_API_BASEURL
        },
        {
          rel: 'alternate',
          href: this.siteService.getActiveSiteAddress(),
          hreflang: 'pl-pl'
        }
      ],
      titleTemplate: `%s | ${this.siteName}`,
      meta: [
        ...this.siteService.getActiveSiteMeta(),
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileImage',
          content: `${process.env.BASE_URL}img/icons/mstile-144x144.png`
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteName
        },
        {
          name: 'application-name',
          content: this.siteName
        }
      ],
      script: this.checkLighthouseHeaders ? [] : [
        ...(process.env.VUE_APP_GTM_API_KEY ? [
          {
            once: true,
            vmid: 'gtm-container',
            async: false,
            defer: false,
            innerHTML: `
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'info'
          });
          dataLayer.push({
            originalLocation: document.location.protocol + '//' +
                              document.location.hostname +
                              document.location.pathname +
                              document.location.search
          });
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
            'wait_for_update': 2500
          });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
          event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'
          ?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',
          '${process.env.VUE_APP_GTM_API_KEY}');`,
            type: 'text/javascript'
          }
        ] : []),
        ...(process.env.VUE_APP_SYNERISE_API_KEY ? [
          {
            once: true,
            defer: true,
            vmid: 'synerise-container',
            innerHTML: `
          function onSyneriseLoad () {
            SR.init({
              'trackerKey':'${process.env.VUE_APP_SYNERISE_API_KEY}',
              'customPageVisit': true,
              'dataLayer': window.dataLayer,
              'dynamicContent': {
                'virtualPage': true
              },
              'trackingDomain': ' https://apisr.aelia.pl'
            });
          }

          (function (s, y, n, e, r, i, se) {
            s['SyneriseObjectNamespace'] = r;
            s[r] = s[r] || [],
              s[r]._t = 1 * new Date(), s[r]._i = 0, s[r]._l = i;
            var z = y.createElement(n),
              se = y.getElementsByTagName(n)[0];
            z.async = 1;
            z.src = e;
            se.parentNode.insertBefore(z, se);
            z.onload = z.onreadystatechange = function () {
              var rdy = z.readyState;
              if (!rdy || /complete|loaded/.test(z.readyState)) {
                s[i]();
                z.onload = null;
                z.onreadystatechange = null;
              }
            };
          })(window, document, 'script', '//apisr.aelia.pl/0w8c10p0.js', 'SR', 'onSyneriseLoad');
          `,
            type: 'text/javascript'
          }
        ] : []),
        {
          once: true,
          defer: true,
          vmid: 'dhl-container',
          innerHTML: `
          /*<![CDATA[*/
            //
            // Read DHL24 docs: https://dhl24.com.pl/mapa/doc.html
            //
            function listenMessage (msg) {
              if (typeof msg.data === 'object') {
                localStorage.setItem('cart:dhl_pickup_point', JSON.stringify(msg.data))
                const data = JSON.parse(localStorage.getItem('cart:dhl_pickup_point'))
                if (typeof data.id === 'undefined') {
                  localStorage.removeItem('cart:dhl_pickup_point')
                }
              } else {
                localStorage.setItem('cart:dhl_pickup_point', JSON.stringify(JSON.parse(msg.data)))
                const data = JSON.parse(localStorage.getItem('cart:dhl_pickup_point'))
                if (typeof data.id === 'undefined') {
                  localStorage.removeItem('cart:dhl_pickup_point')
                }
              }
            }
            if (window.addEventListener) {
              window.addEventListener("message", listenMessage, false);
            } else {
              window.attachEvent("onmessage", listenMessage);
            }
          /*]]>*/
          `,
          type: 'text/javascript'
        },
        {
          once: true,
          defer: true,
          vmid: 'tradedoubler-container',
          innerHTML: `
            function setCookie(name, value, expires, path, domain, secure) {
              var today = new Date();
              today.setTime( today.getTime() );
              if ( expires ){
                  expires = expires * 1000 * 60 * 60 * 24;
              }
              var expires_date = new Date( today.getTime() + (expires) );
              document.cookie= name + "=" + escape(value) +
              ((expires) ? "; expires=" + expires_date.toGMTString() : "") +
              ((path) ? "; path=" + path : "") +
              ((domain) ? "; domain=" + domain : "") +
              ((secure) ? "; secure" : "");
            }
            function getVar(name) {
                get_string = document.location.search;
                return_value = '';
                do {
                    name_index = get_string.indexOf(name + '=');
                    if(name_index != -1) {
                        get_string = get_string.substr(name_index + name.length + 1, get_string.length - name_index);
                        end_of_value = get_string.indexOf('&');
                        if(end_of_value != -1) {
                            value = get_string.substr(0, end_of_value);
                        } else {
                            value = get_string;
                        }
                        if(return_value == '' || value == '') {
                            return_value += value;
                        } else {
                            return_value += ', ' + value;
                        }
                    }
                } while(name_index != -1) {
                    space = return_value.indexOf('+');
                }
                while(space != -1){
                    return_value = return_value.substr(0, space) + ' ' + return_value.substr(space + 1, return_value.length);
                    space = return_value.indexOf('+');
                }
                return(return_value);
            }
            var mytduid = getVar('tduid');
            if (mytduid != '') {
              setCookie('TRADEDOUBLER', mytduid, 365, '/', window.location.hostname);
            }
          `,
          type: 'text/javascript'
        },
        ...(process.env.VUE_APP_TRADEDOUBLER_WRAP_ID ? [
          {
            once: true,
            defer: true,
            type: 'text/javascript',
            src: `https://swrap.tradedoubler.com/wrap?id=${process.env.VUE_APP_TRADEDOUBLER_WRAP_ID}`
          }
        ] : []),
        ...(process.env.VUE_APP_CENEO_GUID &&
        process.env.VUE_APP_CENEO_GUID !== '__SC_VUE_APP_CENEO_GUID__' // temp
          ? [
            {
              once: true,
              defer: true,
              vmid: 'ceneo-container',
              innerHTML: `
            (function(w,d,s,i,dl){w._ceneo = w._ceneo || function () {
              w._ceneo.e = w._ceneo.e || []; w._ceneo.e.push(arguments); };
              w._ceneo.e = w._ceneo.e || [];dl=dl===undefined?"dataLayer":dl;
              const f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.defer = true;
              j.src = "https://ssl.ceneo.pl/ct/v5/script.js?accountGuid=" + i + "&t=" + Date.now() + (dl ? "&dl=" + dl : ''); f.parentNode.insertBefore(j, f);
            })(window, document, "script", "${process.env.VUE_APP_CENEO_GUID}");
          `,
              type: 'text/javascript'
            }
          ] : [])
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'gtm-container': ['innerHTML'],
        'synerise-container': ['innerHTML'],
        'dhl-container': ['innerHTML'],
        'tradedoubler-container': ['innerHTML'],
        'ceneo-container': ['innerHTML']
      }
    }
  }
})
export class App extends Mixins(CoreMixin, HasTheme, ProductCartMixin) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get checkLighthouseHeaders () {
    return this.$store.getters.getUserAgent.indexOf('Chrome-Lighthouse') > -1
  }

  /**
   * Determines whether there are any errors to display.
   */
  public get hasErrors (): boolean {
    return !isLocalEnv() && this.$errorHandler.error !== null &&
      this.$errorHandler.statusCode !== 404
  }

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Provide(IS_MOBILE_PROVIDER_KEY)
  public isMobile (): boolean {
    return this.$store.getters.isMobile
  }

  /**
   * When un-authenticated.
   */
  public onAuthRequired (): void {
    this.requireAuthentication()
  }

  /**
   * Determines site name.
   */
  public get siteName (): string {
    const activeSite = this.siteService.getActiveSite()
    if (!Object.prototype.hasOwnProperty.call(activeSite, 'name') && !activeSite.name) {
      return ''
    }
    return activeSite.name
  }

  mounted () {
    if (window) {
      window.addToCart = (sku: string) => {
        this.addToCartByProductSku(sku)
      }
    }
  }
}

export default App
