






import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { BrandTeaserModule } from '../BrandTeaser.contracts'
import { BrandTeaserModuleVersion, brandTeaserModuleVersionComponentRegistry } from '../BrandTeaser.config'
import { AsyncComponent } from 'vue'
import { logger } from '../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<BrandTeaserModuleUi>({
  name: 'BrandTeaserModuleUi'
})
export class BrandTeaserModuleUi extends AbstractModuleUi<BrandTeaserModule> {
  protected versionEnum = BrandTeaserModuleVersion

  /**
   * Render version component
   */
  public get component (): AsyncComponent | undefined {
    const component = brandTeaserModuleVersionComponentRegistry[this.brandTeaserVersion]

    if (typeof component === 'undefined') {
      logger(
        `BrandTeaserModule.ui.component(): There's no Vue component associated with the [${this.data.version}] BrandTeaserVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Set default version of BrandTeaser
   */
  public get brandTeaserVersion () {
    if (!this.data.version) {
      return BrandTeaserModuleVersion.Default
    }

    return this.data.version
  }

  /**
   * Determines whether brands defined.
   */
  public hasBrands (): boolean {
    return !!this.content.brands && this.content.brands.length > 0
  }
}

export default BrandTeaserModuleUi
