import { Component, Inject as VueInject, Vue, Watch } from 'vue-property-decorator'

import { IWindow, WindowType } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../support'

import { ScrollDirection } from '../contracts/scroll'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<WindowScrollMixin>({
  name: 'WindowScrollMixin',
  mounted () {
    this.initWindowListener()
  },
  beforeDestroy () {
    this.scrollDirection = null
    this.clearListeners()
  }
})
export class WindowScrollMixin extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(WindowType)
  protected readonly windowService!: IWindow

  protected readonly TOP_THRESHOLD = 20
  protected readonly SCROLL_DOUBLE_TIMEOUT = 500

  /** Stores scroll current direction */
  public scrollDirection: ScrollDirection | null = null

  protected currentScrollY: number = this.getScrollY()
  protected isDouble: boolean = false
  protected isUpdating: boolean = false
  protected prevScrollY: number = this.getScrollY()
  protected rafId: number | null = null
  protected shouldDouble: boolean = false
  protected topThreshold: number = this.TOP_THRESHOLD
  protected timeout: NodeJS.Timeout | null = null

  public get isScrollUp (): boolean {
    return this.scrollDirection === ScrollDirection.Up
  }

  public get isScrollDown (): boolean {
    return this.scrollDirection === ScrollDirection.Down
  }

  public get isTop (): boolean {
    return this.currentScrollY <= this.topThreshold
  }

  public get mobile (): boolean {
    return this.isMobile()
  }

  public get scrollClass (): string {
    let className = ''

    if (this.isTop) {
      return 'scroll--on-top'
    }

    switch (this.scrollDirection) {
      case ScrollDirection.Up:
        className = 'scroll--up'
        break
      case ScrollDirection.Down:
        className = 'scroll--down'
        break
    }

    return className + (this.isDouble ? ' scroll--double' : '')
  }

  public getScrollY (): number {
    if (this.windowService?.isServer || !this.windowService?.native) {
      return 0
    }

    const window = this.windowService.native
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset
    }

    return (window.document.documentElement || window.document.body.parentNode || window.document.body).scrollTop
  }

  public considerDoubling (prevDirection: ScrollDirection | null): void {
    if (prevDirection && this.scrollDirection !== prevDirection) {
      this.shouldDouble = false
      this.isDouble = false

      if (this.timeout) {
        clearTimeout(this.timeout)
      }
    } else {
      if (this.shouldDouble) {
        this.isDouble = true
      } else {
        this.timeout = setTimeout(() => {
          this.shouldDouble = true
        }, this.SCROLL_DOUBLE_TIMEOUT)
      }
    }
  }

  public detectScrollDirection (): ScrollDirection {
    return (this.currentScrollY > this.prevScrollY) ? ScrollDirection.Down : ScrollDirection.Up
  }

  public updatePosition (): void {
    const prevDirection = this.scrollDirection
    this.prevScrollY = this.currentScrollY
    this.currentScrollY = this.getScrollY()
    this.scrollDirection = this.detectScrollDirection()

    this.considerDoubling(prevDirection)

    this.isUpdating = false
  }

  public onWindowScroll (): void {
    if (!this.isUpdating) {
      this.isUpdating = true
      this.rafId = requestAnimationFrame(this.updatePosition)
    }
  }

  /**
   * Sets window scroll listener on #app Element on mobile
   * @protected
   */
  @Watch('mobile')
  protected initWindowListener (): void {
    if (this.windowService?.isClient) {
      if (this.isMobile()) {
        this.windowService.addEventListener('scroll', this.onWindowScroll, { passive: true, capture: false })
      } else {
        this.clearListeners()
      }
    }
  }

  /**
   * Clears all scroll listeners
   * @protected
   */
  protected clearListeners (): void {
    if (this.windowService?.isClient) {
      this.windowService.removeEventListener('scroll', this.onWindowScroll, { passive: true, capture: false })
    }
  }
}

export default WindowScrollMixin
