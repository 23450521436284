
























import { Component, Inject as VueInject, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { ISiteService, SiteServiceType } from '../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import { ReviewCard, ReviewCardProps } from '../../molecules/ReviewCard'

import {
  REVIEWS_TEASER_COMPONENT_KEY,
  REVIEWS_TEASER_DEFAULT_CONFIG,
  REVIEWS_TEASER_DEFAULT_COUNT
} from './ReviewsTeaser.config'
import { ReviewsTeaserProps } from './ReviewsTeaser.contracts'
import { translateTrustedReviewToReviewsTeaser } from './ReviewsTeaser.helpers'
import { ITrustedShop, TrustedShopRepositoryType } from '../../repositories/trustedShop'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ReviewsTeaser>({
  name: 'ReviewsTeaser',
  components: {
    ReviewCard
  },
  async created () {
    this.config = this.getComponentConfig(REVIEWS_TEASER_COMPONENT_KEY, { ...REVIEWS_TEASER_DEFAULT_CONFIG })

    /**
     * @inheritDoc
     */
    await this.loadServiceReviews()
  }
})
export class ReviewsTeaser extends StructureConfigurable {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Inject(TrustedShopRepositoryType)
  protected readonly trustedShopRepository?: ITrustedShop

  @Prop({ type: Boolean, required: true })
  public readonly hasSummary!: ReviewsTeaserProps['hasSummary']

  @Prop({ type: Object, required: false })
  public readonly heading?: ReviewsTeaserProps['heading']

  @Prop({ type: Number, required: true })
  public readonly reviewsPerPage!: ReviewsTeaserProps['reviewsPerPage']

  @Prop({ type: Number, required: true })
  public readonly reviewsCount!: ReviewsTeaserProps['reviewsCount']

  public reviews: Array<ReviewCardProps> | null = null
  protected config!: AnyObject

  public get shouldRenderSummaryCard (): boolean {
    return typeof this.hasSummary !== 'undefined' && typeof this.aggregateRating !== 'undefined' && this.hasSummary
  }

  public get carouselConfig (): AnyObject {
    return {
      ...this.config.carouselConfig,
      responsive: {
        ...this.config.carouselConfig.responsive,
        desktop: {
          ...this.config.carouselConfig.responsive.desktop,
          perPage: this.reviewsPerPage
        }
      }
    }
  }

  public get aggregateRating () {
    const aggregateRating = this.$store.getters['shared/getTrustedShopAggregateRating']

    if (!aggregateRating) {
      return
    }

    return {
      aggregateRating: aggregateRating.avgRating,
      image: {
        src: aggregateRating.img
      },
      link: {
        label: this.$t('front.shared.reviewCard.allReviews').toString(),
        target: aggregateRating.link.target
      },
      maxRating: aggregateRating.maxRating
    }
  }

  /**
   * Retrieves a list of service reviews
   * @protected
   */
  protected async loadServiceReviews (): Promise<void> {
    if (!this.trustedShopRepository) {
      return
    }

    try {
      const reviews = await this.trustedShopRepository.getServiceReviews(REVIEWS_TEASER_DEFAULT_COUNT, true)
      this.reviews = translateTrustedReviewToReviewsTeaser(reviews.items).slice(0, this.reviewsCount)
    } catch (e) {
      logger(e, 'warn')
    }
  }
}
export default ReviewsTeaser
