

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { UiMarkdown } from '../../../../../modules/partials/UiMarkdown'

@Component<CartPromotionBox>({
  name: 'CartPromotionBox',
  components: { UiMarkdown }
})
export class CartPromotionBox extends Vue {
  @Prop({ type: Array, required: true })
  public readonly cartPromotionsDescriptions!: []
}

export default CartPromotionBox
