// Copyright © 2022 Move Closer

import { CarouselProps } from '../../../../dsl/molecules/Carousel'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_CAROUSEL_DEFAULT_CONFIG: CarouselProps = {
  draggable: true,
  hasPagination: false,
  inLoop: false,
  pauseOnHover: false,
  perPage: 1,
  swipeToSlide: true,
  variableWidth: true,
  responsive: {
    mobile: {
      perPage: 1,
      hasButtons: false
    },
    desktop: {
      perPage: 1,
      hasButtons: true
    }
  }
}

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_KEY = 'VariantsSwitch'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export interface VariantsSwitchConfig {
  carouselConfig: CarouselProps
}

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const VARIANTS_SWITCH_CONFIG_MAP: VariantsSwitchConfig = {
  carouselConfig: VARIANTS_SWITCH_CAROUSEL_DEFAULT_CONFIG
}
