
































import { Component, Mixins, PropSync } from 'vue-property-decorator'

import { AirportScheduleItem } from '../../../../contexts/checkout/contracts/flight-data'
import { Inject, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'

import {
  FlightsServiceKey,
  IFlightsService
} from '../../../shared/services/flights'

import { CartMutationTypes, CheckoutPayload } from '../../contracts'
import { CheckoutServiceType, ICheckoutService } from '../../services/checkout'
import FlightDataFieldset from '../../molecules/FlightDataFieldset/FlightDataFieldset.vue'

import { AbstractStep } from '../AbstractStep'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FlightDataStep>({
  name: 'FlightDataStep',
  components: {
    FlightDataFieldset
  },
  async mounted () {
    this.loadFlightDate()
    await this.fetchAirportsSchedules()
    this.cachedFlightsSchedule = this.cachedFlightsSchedule
      .map((flight) => {
        return {
          ...flight,
          flight: flight.flight.trimEnd()
        }
      })
    this.populateFormDataWithPayload()
  }
})
export default class FlightDataStep extends Mixins(AbstractStep, StructureConfigurable) {
  @Inject(CheckoutServiceType)
  protected readonly checkoutService!: ICheckoutService

  @Inject(FlightsServiceKey)
  protected readonly flightsService?: IFlightsService

  @PropSync('payload', { type: Object, required: true })
  public _payload!: CheckoutPayload

  public clearFlights: boolean = false
  public errors: Record<string, string[]> | null = null
  public customError: string | null = null
  public flightDate: string | null = null
  public isLoading: boolean = false

  public get formData (): CheckoutPayload {
    return this._payload
  }

  public set formData (values: CheckoutPayload) {
    for (const [key, value] of Object.entries(values)) {
      this.onChange(key, value)
    }
  }

  public get cachedFlightsSchedule (): Array<AirportScheduleItem> {
    return !this.clearFlights
      ? this.$store.getters['checkout/flightsSchedule']
        .sort((a: AirportScheduleItem, b: AirportScheduleItem) => a.flight > b.flight ? 1 : -1)
      : []
  }

  public set cachedFlightsSchedule (values: Array<AirportScheduleItem>) {
    this.$store.commit(CartMutationTypes.SetFlightsSchedule, values)
  }

  public clearErrors (): void {
    this.customError = null
    this.errors = null
  }

  public async submit (): Promise<void> {
    if (!this.cart) {
      return
    }

    if (!this.flightDate || !this._payload.flightNumber) {
      this.errors = {
        ...(!this.flightDate ? {
          flightDate: [
            this.$t('front.checkout.molecules.FlightDataFieldset.formData.flightDate.error')
              .toString()
          ]
        } : {}),
        ...(!this._payload.flightNumber ? {
          flightNumber: [
            this.$t('front.checkout.molecules.FlightDataFieldset.formData.flightNo.error')
              .toString()
          ]
        } : {})
      }

      return
    }

    if (this.customError) {
      this.nextStep()
      return
    }

    this.$emit('saving', true)

    try {
      const updatedCart = await this.checkoutService.setFlightNumberOnCart(
        this.cart.id,
        this._payload.flightNumber.trimEnd()
      )
      this.$store.commit(CartMutationTypes.SetCart, updatedCart)

      this.nextStep()
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.$emit('saving', false)
    }
  }

  public isDateInPast (newDate: string | null): boolean {
    const today = new Date()
    return !!newDate && Number(new Date(newDate).getTime()) < Number(today.getTime() - 1000 * 60 * 60 * 24)
  }

  /**
   * Updated flights based on date.
   * Prevents sending api request when new date is the same as previous
   */
  public async updateFlightsDate (newDate: string | null): Promise<void> {
    this.customError = null

    if (this.isDateInPast(newDate)) {
      this.clearFlights = true
      this.customError = this.$t('front.checkout.organisms.FlightDataStep.pastDate').toString()
      return
    }

    const sameDate = this.flightDate === newDate

    this.clearFlights = !newDate
    this.flightDate = newDate ?? ''

    if (newDate && !sameDate) {
      await this.fetchAirportsSchedules(true)
    }

    const newFlightNumber = newDate
      ? sameDate
        ? this._payload.flightNumber
        : null
      : ''

    this.onChange('flightNumber', newFlightNumber)

    localStorage.setItem(`cart:flightDate:${this.cart.id}`, this.flightDate)
  }

  protected async fetchAirportsSchedules (updateResults: boolean = false): Promise<void> {
    if (!this.flightsService || !this.flightDate) {
      return
    }

    if (!updateResults && this.cachedFlightsSchedule.length > 0) {
      return
    }

    this.clearErrors()
    this.$emit('saving', true)

    try {
      const schedule = await this.flightsService.getAirportsSchedule(this.flightDate)

      if (schedule) {
        this.$store.commit(CartMutationTypes.SetFlightsSchedule, schedule.map(element => ({
          ...element,
          flight: element.flight.split(' ').join(''),
          date: this.flightDate
        })))
      }
    } catch (e) {
      this.customError = this.$t('front.checkout.organisms.FlightDataStep.fetchError').toString()
      this.clearFlights = true
      logger(e, 'warn')
    } finally {
      this.$emit('saving', false)
    }
  }

  protected loadFlightDate (): void {
    this.flightDate = localStorage.getItem(`cart:flightDate:${this.cart.id}`)
  }

  /**
   * Creates formData with random airport
   */
  protected populateFormDataWithPayload (): void {
    const hasFlightNumber = !!this._payload.flightNumber
    const selectedFlight = hasFlightNumber
      ? this.cachedFlightsSchedule.find(flight => flight.flight === this._payload.flightNumber)
      : null

    const flightNumber = selectedFlight
      ? selectedFlight.flight
      : null

    this.onChange('flightNumber', flightNumber)
  }
}

