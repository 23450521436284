





























/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { AllowedImageRatio, ImageData } from '../../../../../contexts'

import { toImageProps } from '../../../../shared/support'
import { CarouselProps } from '../../../../../dsl/molecules/Carousel'

import { Variant, VariantsSwitchProps } from '../VariantsSwitch.contracts'

@Component<Default>({
  name: 'Default',

  mounted () {
    window.addEventListener('resize', this.updateNavigationVisibility)
  },

  destroyed () {
    window.removeEventListener('resize', this.updateNavigationVisibility)
  }
})
export class Default extends Vue {
  @PropSync('model', {
    required: false,
    default: null
  })
  public _model!: VariantsSwitchProps['model']

  @Prop({
    type: Array,
    required: false
  })
  public variants?: Variant[]

  @Prop({ type: Object, required: true })
  public readonly carouselConfig!: CarouselProps

  public config: CarouselProps = this.carouselConfig

  public getStyle (identifier: string | ImageData): AnyObject {
    if (typeof identifier === 'string') {
      return { backgroundColor: identifier }
    }

    return { backgroundImage: `url(${toImageProps(identifier, AllowedImageRatio.Square, 50).src})` }
  }

  public updateNavigationVisibility () {
    if (this.$refs.carousel) {
      const carusel = this.$refs.carousel as HTMLDivElement
      const carouselWidth = carusel.offsetWidth
      const totalSlidesWidth = Array.from(carusel.querySelectorAll('.slick-slide')).reduce(
        (acc, slide) => acc + (slide as HTMLElement).offsetWidth,
        0
      )

      this.config.hasButtons = totalSlidesWidth > carouselWidth
    }
  }
}
export default Default
