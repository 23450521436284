






















import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { AsyncComponent, VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import { AllowedAttributes } from '../../../../contexts'
import { Inject } from '../../../../support'
import { LoyaltyData } from '../../../loyalty/contracts/loyalty'
import { UserData, UserModel } from '../../../auth/shared'

import { CartModel, CheckoutPayload } from '../../contracts'
import { RouteName as CheckoutRoutes } from '../../routes'

import { Step } from '../Step'
import {
  CIRCULAR_STEPPER_COMPONENT_KEY,
  defaultComponentConfig,
  stepsComponentConstructor
} from './CircularStepper.config'
import { StepAttributes } from './CircularStepper.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CircularStepper>({
  name: 'CircularStepper',
  components: { Step },
  created (): void {
    this.config = this.getComponentConfig(CIRCULAR_STEPPER_COMPONENT_KEY, defaultComponentConfig)
    this.$emit('step', { step: this._currentStep, lastStep: this.steps.length + 1 })
  }
})
export class CircularStepper extends Mixins<StructureConfigurable>(StructureConfigurable) {
  @Prop({ type: Object, required: true })
  public cart!: CartModel

  @Prop({ required: false })
  public user!: UserData | null

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @PropSync('payload', { type: Object, required: true })
  public _payload!: CheckoutPayload

  @PropSync('currentStep', { type: Number, required: true })
  public _currentStep!: number

  public CheckoutRoutes = CheckoutRoutes

  public data: AnyObject = {}
  public error: string | null = null
  public displayBackToCart: boolean = false

  public get hasBillingAddressSet (): boolean {
    return this.cart
      ? this.cart.billingAddress ? this.cart.billingAddress.street.length > 0 : false
      : false
  }

  public get loyalty (): LoyaltyData | null {
    return this.$store.getters['shared/getLoyalty']
  }

  /**
   * Consider mutating available steps when user is registering in checkout
   */
  public get considerOtherStepsWhenUserRegisters (): boolean {
    return this.getConfigProperty('considerOtherStepsWhenUserRegisters')
  }

  /**
   * Consider mutating available steps when user has already submitted and has valid data step
   */
  public get considerOtherStepsWhenDataStepIsSet (): boolean {
    return this.getConfigProperty('considerOtherStepsWhenDataStepIsSet')
  }

  /**
   * Consider showing step when user belongs to Friends&Family program
   */
  public get considerOtherStepsWhenLoyalty (): boolean {
    return this.getConfigProperty('considerOtherStepsWhenLoyalty')
  }

  public get stepsComponentConstructor (): VueConstructor | AsyncComponent {
    const possibleSteps = Object.values(this.steps).map((step) => {
      return step.type
    })

    return stepsComponentConstructor[possibleSteps[this._currentStep - 1]][this.steps[this._currentStep - 1].version]
  }

  public get shouldSkipCustomRules (): boolean {
    return !this.cart.items.some((item) => !!Object.values(item.product.variants)[0].attributes[AllowedAttributes.FlightNumberRequired])
  }

  public get steps (): Array<StepAttributes> {
    const candidateSteps = Object.values(this.getConfigProperty<AnyObject>('steps')).filter((step: StepAttributes) => {
      return this._payload.isUserLoggedIn ? true : !step.auth
    })

    return this.considerCustomStepRules(candidateSteps, this.shouldSkipCustomRules)
  }

  /**
   * Applies custom rules for steps rendering
   */
  public considerCustomStepRules (steps: Array<StepAttributes>, skipRules: boolean = true): Array<StepAttributes> {
    if (skipRules) {
      return steps.filter((step: StepAttributes) => !step.skipWhenDefault)
    }

    if (this.considerOtherStepsWhenDataStepIsSet) {
      steps = steps.filter((step: StepAttributes) => this.hasBillingAddressSet ? true : !step.showWhenDataStepCompleted)
    }

    if (this.considerOtherStepsWhenUserRegisters) {
      steps = steps.filter((step: StepAttributes) => (!!this.cart.isSignupRequested && this.hasBillingAddressSet) ? true : !step.registering)
    }

    if (this.considerOtherStepsWhenLoyalty) {
      steps = steps.filter((step: StepAttributes) => (this.user && this.loyalty && this.loyalty.friendsAndFamily) ? !step.excludeWhenLoyalty : true)
    }

    return steps
  }

  public onError (message: string): void {
    if (message === 'Some of the products are out of stock.') { // todo: api!
      this.error = 'Niektóre z Twoich produktów są niedostępne w żądanej ilości.'
    } else {
      this.error = message
    }

    this.displayBackToCart = (message === 'Żądana ilość nie jest dostępna') ||
      (message === 'Niektóre z Twoich produktów są niedostępne w żądanej ilości') ||
      (message === 'Some of the products are out of stock.') ||
      (message === 'Niektóre z Twoich produktów są niedostępne w żądanej ilości.') ||
      (message === 'Niektóre z Twoich produktów są niedostępne w żądanej ilości')

    if (this.displayBackToCart) {
      this.$emit('reloadCart')
    }
  }

  public isSignupRequested (value: boolean): void {
    this._payload = {
      ...this._payload,
      isSignupRequested: value
    }
  }

  /**
   * Conditions that when are met should skip considered step
   * @param step
   */
  public considerNextStepForSkipCondition (step: string): number {
    const skipBy = 1
    let next = 1

    switch (step) {
      case 'flightData':
        next = this._payload.flightNumber ? next + skipBy : next
    }

    return next
  }

  /**
   * Handles next step.
   */
  public nextStep (): void {
    this.error = null

    if (this.steps[this._currentStep] && this.steps[this._currentStep].skipWhenSet) {
      this._currentStep = this._currentStep + this.considerNextStepForSkipCondition(this.steps[this._currentStep].type)
    } else {
      this._currentStep++
    }
  }

  public onSaving (value: boolean): void {
    if (value) this.error = null
    this.$emit('saving', value)
  }

  /**
   * Updates the payload by a new value
   */
  public updateKey (key: string, value: unknown): void {
    this._payload = {
      ...this._payload,
      [key]: value
    }
  }
}
export default CircularStepper
