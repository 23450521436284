



















































import { Component } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { MetaInfo } from 'vue-meta'

import { AllowedImageRatio } from '../../../contexts'

import { AbstractModuleUi } from '../../abstract/ui'
import { BenefitsBar } from '../../../front/shared/molecules/BenefitsBar'
import { CarouselProps } from '../../../dsl/molecules/Carousel'
import { toImageProps } from '../../../front/shared/support'

import {
  AddonType,
  HeroModule, PossibleAddons,
  Slide
} from '../Hero.contracts'

import {
  HERO_COMPONENT_KEY,
  HERO_DEFAULT_CONFIG,
  heroModuleAddonsUiRegistry
} from './Hero.ui.config'
import { useSrcSet } from '../../../dsl/composables/src-set'

/**
 * Container component for the `HeroModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<HeroModuleUi>({
  name: 'HeroModuleUi',
  components: { BenefitsBar },
  created (): void {
    this.config = this.getComponentConfig(HERO_COMPONENT_KEY, { ...HERO_DEFAULT_CONFIG })
  },
  metaInfo (): MetaInfo {
    if (!this.slides.length) {
      return {}
    }

    const mainImage = this.backgroundImg(this.slides[0])

    if (!mainImage) {
      return {}
    }

    const { _srcset } = useSrcSet(mainImage)

    return {
      link: [
        {
          rel: 'preload',
          fetchpriority: 'high',
          as: 'image',
          type: 'image/webp',
          href: mainImage.src,
          ...(_srcset?.value ? { imagesrcset: _srcset?.value } : {})
        }
      ]
    }
  }
})
export class HeroModuleUi extends AbstractModuleUi<HeroModule> {
  /**
   * Determines active slide index.
   */
  public activeSlideIndex = 0

  /**
   * Determines the content color of the
   */
  public contentColor (slide: Slide): string {
    return typeof slide.contentColor === 'undefined' ? 'default' : slide.contentColor
  }

  public get carouselConfig (): CarouselProps {
    return this.getConfigProperty('carouselConfig')
  }

  public get linkDecorator (): boolean {
    return this.getConfigProperty('linkDecorator')
  }

  public get linkTheme (): boolean {
    return this.getConfigProperty('linkTheme')
  }

  /**
   * Determines whether `Hero` has benefits bar.
   */
  public get hasBenefitsBar (): boolean {
    return this.getConfigProperty('hasBenefitsBar')
  }

  public get hasButtons (): boolean {
    return this.carouselConfig.hasButtons ||
    this.isMobile()
      ? !!this.carouselConfig.responsive?.mobile.hasButtons
      : !!this.carouselConfig.responsive?.desktop.hasButtons
  }

  /**
   * Determines hero slides
   */
  public get slides () {
    if (!this.content.slides?.length) {
      return []
    }

    return this.content.slides
  }

  /**
   * Determines is creations products should render
   */
  public get shouldRender (): boolean {
    return this.content.slides.length > 0
  }

  /**
   * Determines slide addon module
   */
  public getModule (type: AddonType): VueConstructor {
    return heroModuleAddonsUiRegistry[type]
  }

  public get timerAddon (): VueConstructor {
    return heroModuleAddonsUiRegistry[PossibleAddons.Timer]
  }

  /**
   * Determines is hero slide has addon.
   */
  public hasAddon (slide: Slide) {
    return typeof slide.addon !== 'undefined' && !!slide.addon.type && !!slide.addon.content
  }

  public addonIs (slide: Slide, type: string): boolean {
    return slide.addon?.type === type
  }

  /**
   * Carousel slide change event handle
   */
  public onSlideChange (activeSlideIndex: number) {
    this.activeSlideIndex = activeSlideIndex
  }

  /**
   * Determines slide styles
   */
  public backgroundImg (slide: Slide) {
    if (!slide) {
      return
    }

    if (!slide.background) {
      // @FIXME
      return { src: '', alt: '' }
    }

    return toImageProps(slide.background, AllowedImageRatio.Original)
  }
}

export default HeroModuleUi
