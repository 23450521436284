


















import { Component } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { toImageProps } from '../../../../front/shared/support'
import { ImageProps } from '../../../../dsl/atoms/Image'

import { AllowedImageRatio, AllowedImageWidth, CategoryData, ImageData } from '../../../../contexts'

import { AbstractModuleUi } from '../../../abstract/ui'

import { BrandData, BrandTeaserModule } from '../../../BrandTeaser/BrandTeaser.contracts'
import { brandTeaserDefaultCarouselConfig } from '../../../BrandTeaser/BrandTeaser.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'Default'
})
export class Default extends AbstractModuleUi<BrandTeaserModule> {
  /**
   * Determines active slide index.
   */
  public activeSlideIndex = 0

  /**
   * Get brands
   */
  public get brands (): BrandData[] {
    if (!this.content.brands || this.content.brands.length === 0) {
      return []
    }

    return this.content.brands
  }

  /**
   * Determines slides container width.
   */
  public get slidesContainerWidth (): string {
    const device = this.isMobile() ? 'mobile' : 'desktop'
    const perPage = this.content.perPage[device]
    if (!perPage) {
      return 'auto'
    }

    return `${(100 / perPage)}%`
  }

  /**
   * Determines brand target
   */
  public linkProps (category: CategoryData | null) {
    return {
      target: category?.url ?? '',
      title: category?.name ?? ''
    }
  }

  /**
   * Carousel configuration
   */
  public get brandTeaserCarouselConfig (): AnyObject {
    return {
      ...brandTeaserDefaultCarouselConfig,
      responsive: {
        desktop: {
          ...brandTeaserDefaultCarouselConfig.responsive.desktop,
          perPage: this.content.perPage.desktop
        },
        mobile: {
          ...brandTeaserDefaultCarouselConfig.responsive.mobile,
          perPage: this.content.perPage.mobile
        }
      }
    }
  }

  /**
   * Determines image props.
   */
  public getImageProps (image: ImageData): ImageProps {
    if (typeof image === 'undefined') {
      return {
        src: '',
        alt: ''
      }
    }

    return toImageProps(image, AllowedImageRatio.Original, AllowedImageWidth.BrandLogo)
  }

  /**
   * Carousel slide change event handle
   */
  public onSlideChange (activeSlideIndex: number) {
    this.activeSlideIndex = activeSlideIndex
  }
}

export default Default
