import { render, staticRenderFns } from "./DataFormEntity.vue?vue&type=template&id=518d64a2&"
import script from "./DataFormEntity.vue?vue&type=script&lang=ts&"
export * from "./DataFormEntity.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports