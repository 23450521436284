import { IConnector, Injectable, IResponse, mapModel, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../support'

import { IGraphQL } from '../../../contexts'

import {
  IAuthControl,
  LoginFormPayload,
  RequestResetLinkPayload,
  RequestResetPasswordPayload,
  SignupFormPayload, SocialAuthRedirectUri, SocialAuthType, SSOCallbackParams, SSOValidCallbackResponse
} from '../contracts'
import { Token } from '../models/token'
import { tokenAdapterMap } from '../models/token.adapter'
import { TokenModel } from '../contracts/models'
import { ILoyaltyService } from '../../loyalty/contracts/services'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Injectable()
export class AuthControl implements IAuthControl {
  protected graphQlConnector: IGraphQL
  protected connector: IConnector
  protected loyaltyService?: ILoyaltyService
  public ssoPending: boolean = false

  constructor (graphQlConnector: IGraphQL, connector: IConnector, loyaltyService?: ILoyaltyService) {
    this.graphQlConnector = graphQlConnector
    this.connector = connector
    this.loyaltyService = loyaltyService
  }

  public SSOPendingStatus () {
    return this.ssoPending
  }

  /**
   * @inheritDoc
   */
  public async checkToken (token: string): Promise<void> {
    // TODO: Remove if not necessary
    return Promise.resolve()
  }

  /**
   * @inheritDoc
   */
  public async login (payload: LoginFormPayload): Promise<TokenModel> {
    const response: IResponse = await this.graphQlConnector.call('loginMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
    const token = response.data.generateCustomerToken.token
    if (!token) {
      throw new ResourceActionFailed(
        'Missing token',
        500
      )
    }

    this.loyaltyService?.clear()

    return Token.hydrate<TokenModel>(
      mapModel<TokenModel>({
        accessToken: token,
        expiresAt: null
      }, tokenAdapterMap, false)
    )
  }

  public async logout (): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('logoutMutation', {})

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    const result = response.data.revokeCustomerToken.result
    if (!result) {
      throw new ResourceActionFailed(
        'Logout failed',
        500
      )
    }

    this.loyaltyService?.clear()
  }

  /**
   * @inheritDoc
   */
  public async requestResetLink (payload: RequestResetLinkPayload): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('requestPasswordMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async resetPassword (payload: RequestResetPasswordPayload): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('requestResetPasswordMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async signup (payload: SignupFormPayload): Promise<TokenModel> {
    const response: IResponse = await this.graphQlConnector.call('createUserMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return await this.login({ email: payload.email, password: payload.password })
  }

  /**
   * @inheritDoc
   */
  public async setSocialCallback (params: SSOCallbackParams, provider: string, sessionId: string | null, acceptContents: boolean | null = null): Promise<SSOValidCallbackResponse | undefined> {
    let socialProvider
    this.ssoPending = true

    switch (provider) {
      case SocialAuthType.Facebook:
        socialProvider = 'callbackFacebook'
        break
      case SocialAuthType.Google:
        socialProvider = 'callbackGoogle'
        break
    }

    if (!socialProvider) {
      return
    }

    let response: IResponse
    if (acceptContents && sessionId) {
      response = await this.connector.call('auth', socialProvider, {}, {
        ...params,
        consents_accepted: '1',
        session_id: sessionId
      })
    } else {
      response = await this.connector.call('auth', socialProvider, {}, { ...params })
    }

    if (response.status === 422 && response.data.message) {
      return {
        id: response.data.data.session_id,
        message: response.data.message,
        status: response.status,
        email: response.data.data.email,
        name: response.data.data.name
      }
    }

    if (response.data.error && response.data.error.error_code === 500) {
      return {
        status: 500
      }
    }

    return {
      token: Token.hydrate<TokenModel>(
        mapModel<TokenModel>({
          accessToken: response.data.data.token,
          expiresAt: null
        }, tokenAdapterMap, false)
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async getSocialAuthRedirectUri (social: string): Promise<SocialAuthRedirectUri> {
    let socialRedirect

    switch (social) {
      case SocialAuthType.Facebook:
        socialRedirect = 'redirectFacebook'
        break
      case SocialAuthType.Google:
        socialRedirect = 'redirectGoogle'
        break
    }

    if (!socialRedirect) {
      throw new ResourceActionFailed(
        'There is no valid social auth type!',
        resolveFromStatus(404),
        {}
      )
    }

    const response: IResponse = await this.connector.call('auth', socialRedirect, {}, {})

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }

    return response.data as unknown as string
  }
}
